<template>
    <ScrollableContainer @state-changed="(showArrow) => this.reachedBottom = !showArrow">
        <div class="content-container">
            <slot name="main"></slot>
            
            <!-- old behaviour -->
            <!-- <div :class="{'hidden': !reachedBottom}">
                <slot name="bottom"></slot>
            </div> -->

            <div>
                <slot name="bottom"></slot>
                <div class="bottom-padding"></div>
            </div>
        </div>
    </ScrollableContainer>
</template>

<script>
export default {
    data() {
        return {
            reachedBottom: false,
        };
    }
}
</script>

<style scoped>
.content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.bottom-padding {
    height: var(--bottom-pad, 100px);
}
</style>