<template>
    <InfoLayout2 :nextPage="{buttonTitle: 'Let’s Go', id: 'GemMechanism05'}">
        <template #header-title>
            <p class="small">Every drop kicks off with a</p>
            <p class="title">Voting Round</p>
        </template>

        <template #main>
            <VoteBox :choices="[
                {version:'A', image:require('@/assets/imgs/goat-nft-1.jpg')}, 
                {version:'B', image:require('@/assets/imgs/goat-nft-2.jpg')}
                ]">
            </VoteBox>

            <br>
            <p class="large">You decide </p>
            <p class="large"><span class="title">which Alpha G.O.A.T</span></p>
            <p class="large">makes the cut</p>

        </template>
    </InfoLayout2>
</template>