<template>
    <InfoLayout1>
        <template #header-img><div class="header-img"></div></template>
        <template #header>Perks</template>
        <template #subtitle>Some Things Are Better Left Unsaid... For Now!</template>
        
        <template #content-div>
            <p>We could spill all the details about the perks right here, right now—but where’s the fun in that? Alpha G.O.A.T isn’t just another NFT collection; it’s an evolving experiment, a journey into the unknown. And some things? Well, they’re best experienced in real time.</p>
            <br>
            <p>What we can say is this: there’s more to these NFTs than meets the eye. Owning one isn’t just about flexing a digital asset—it’s about unlocking something bigger, something dynamic. But if we laid it all out upfront, we’d be spoiling the entire thing. And where’s the thrill in that? This is about more than just instant gratification—it’s about the experience that builds over time, piece by piece, as you dive deeper into the Alpha G.O.A.T world.</p>
            <br>
            <p>So, here’s the deal: stick around, stay engaged, and let the journey unfold. Some perks will reveal themselves in time. Others? You might have to dig a little deeper to find them. But trust us, the thrill of discovering new layers, unlocking hidden benefits, and watching the community grow will be worth every moment of anticipation. But be warned: those who are here for the long haul won’t be disappointed, and the surprises along the way might just be more rewarding than you could ever imagine.</p>        
        </template>

        <template #bottom>
            <div class="bottom">
                <BaseButton pageId="AlphaGoat04">Is that all you got? <i class="icon middle next"></i></BaseButton>
            </div>
        </template>
    </InfoLayout1>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

div.header-img {
    @include responsive-bg-x4('@/assets/imgs/about/perks.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
</style>