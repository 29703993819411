<template>
    <InfoLayout1 class="bottom-pad-override">
        <template #header-img><div class="header-img"></div></template>
        <template #content-div>
            <div class="mixed-content">
                <p>We are <span>the codebreakers, the visionaries</span> of the digital age.</p>
                <br>
                <p>In the shadows of the digital realm, <br><span>we rise as the architects of tomorrow.</span></p>
                <br>
                <p>We do not follow the trends, <br><span>we set them.</span></p>
                <br>
                <p>We don't just adapt <br><span>we innovate.</span></p>
                <br>
                <p>We defy the ordinary and embrace the unknown.</p>
                <br><br>
                <p><span>Art</span> <br>is our weapon of change,</p>
                <br>
                <p><span>Technology</span> <br>is our compass.</p>
                <br><br>
                <p>We collect <br><span>the rare, </span><br><span>the wild, </span><br><span>the extraordinary.</span></p>
                <br><br>
                <p>We are <br><span>the degens, </span><br>the early adopters, <br>the relentless experimenters.</p>
                <br><br>
                <p>We are <br>the round pegs in square holes.</p>
                <br><br>
                <p>We stand in line <br><span>not for the product, </span><br>but <br><span>for the revolution it represents.</span></p>
                <br><br>
                <p>We are the misfits, <br>the dreamers, <br>the ones crazy enough to believe we can change the world.
                </p>
                <br><br>
                <p><span>We are Alpha Labs! </span><br>Join Us!</p>
            </div>

            <div>
                <img src="@/assets/imgs/logo-light.svg" class="logo center">
            </div>
        </template>
    </InfoLayout1>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;
@use '@/assets/css/_base' as base;

div.header-img {
    @include responsive-bg-x4('@/assets/imgs/about/manifesto.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bottom-pad-override {
    --bottom-pad: 0px;
}

.mixed-content span {
    @include base.font-headlines-medium();
}

.logo {
    height: 100px;
    width: auto;
    margin-top: 64px;
    margin-bottom: 64px;
}
</style>