<template>
    <InfoLayout1>
        <template #header-img><div class="header-img"></div></template>
        
        <template #title>Exclusive Access Awaits:</template>
        <template #subtitle>Submit Your VIP Invitation Code & Wallet Address</template>

        <template #content-div>
            <div class="inputs">
                <input ref="invcode" @keyup.prevent="handleKeyUp" @change.prevent="validateInput" type="text" name="invcode" placeholder="Enter your Invitation Code">
                <input ref="wallet" @keyup.prevent="handleKeyUp" @change.prevent="validateInput" type="text" name="wallet" placeholder="Enter your wallet address">
                <input ref="email" @keyup.prevent="handleKeyUp" @change.prevent="validateInput" type="email" name="email" placeholder="Enter you e-mail (optional)">
            </div>

            <LoadingOverlay v-if="isLoading"></LoadingOverlay>
        </template>
        
        <template #bottom>
            <div class="bottom">
                <BaseButton @button-clicked="submit" :active="readyToSubmit">Redeem</BaseButton>
            </div>
        </template>
    </InfoLayout1>
</template>

<script>
import * as backend from '@/backend';
import * as telegram from '@/telegramManager';
import * as errors from '@/errors';

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default {
    inject: ['goToPage'],
    methods: {
        handleKeyUp(e) {
            if(e.key == 'Enter') {
                e.target.blur();
            }
            this.validateInput();
        },
        validateInput() {
            const invcode_elem = this.$refs.invcode;
            const invcode = invcode_elem.value?.toUpperCase() ?? '';
            invcode_elem.value = invcode;

            this.readyToSubmit = false;

            const wallet = this.$refs.wallet.value;
            if (!wallet || wallet.length < 1) {
                return;
            }

            const email = this.$refs.email.value;
            if (email && !EMAIL_REGEX.test(email)) {
                return;     
            }

            if(!invcode || invcode.length < 1) {
                return;
            }

            this.readyToSubmit = true;
        },
        async submit() {
            this.validateInput();
            if (!this.readyToSubmit) {
                console.error('unexpected state: page is not ready to be submitted!');
                return;
            }

            const wallet = this.$refs.wallet.value;
            const email = this.$refs.email.value;
            const invcode = this.$refs.invcode.value;

            this.isLoading = true;
            try {
                const response = await backend.submitInvitation(invcode, wallet, email);
                console.log(response);
                if(response.error) {
                    telegram.showPopupSimple('Error', errors.getDetails(response.error));
                    console.warn('response:', response);
                } else {
                    this.goToPage("HomePage"); //TODO
                }
            } catch (err) {
                console.error(err);
                telegram.showPopupSimple('Error', 'Something went wrong, try again!');
            } finally {
                this.isLoading = false;
            }
        },
    },
    data() {
        return {
            isLoading: false,
            readyToSubmit: false,
        }
    }
}
</script>

<style scoped>
div.header-img {
    background-image: url('@/assets/imgs/invitations/header.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.inputs {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
</style>
