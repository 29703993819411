<template>
    <PageBaseLayout>
        <template #main>
            <div class="backlight"></div>
            <div class="container">
                <div class="inner">
                    <div class="center-children header">
                        <img src="" alt="GEM Protocol" class="clamp-height">
                    </div>
                    <div>
                        <div class="divider-line"></div>
                        <label>Powered by Alpha Labs</label>                        
                    </div>
                    <br>
                    <p class="text-left">GEM is a community-powered minting protocol designed with fairness and user control at its core. Every participant has a voice, making it a truly democratic process where your input shapes the outcome.</p>
                </div>
            </div>
        </template>

        <template #bottom>
            <div class="bottom">
                <BaseButton pageId="GemMechanism02">Discover GEM’s Power &nbsp;<i class="icon middle next"></i></BaseButton>
            </div>
        </template>
    </PageBaseLayout>

</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

// .backlight {
//     background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzkwIiBoZWlnaHQ9IjY3NSIgdmlld0JveD0iMCAwIDM5MCA2NzUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTpjb2xvci1kb2RnZSIgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZl82Nl8xOTEpIj4KPGVsbGlwc2UgY3g9IjY3OS4wMTkiIGN5PSIyMDUuMjQ3IiByeD0iNjc5LjAxOSIgcnk9IjIwNS4yNDciIHRyYW5zZm9ybT0ibWF0cml4KDAuNzY5ODIgLTAuNjM4MjYxIC0wLjYzODI2MSAtMC43Njk4MiAtMTM1Ljk5OCA3ODQuNzg5KSIgZmlsbD0idXJsKCNwYWludDBfcmFkaWFsXzY2XzE5MSkiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9mXzY2XzE5MSIgeD0iLTMwMy4yOTIiIHk9Ii0yODguMDI2IiB3aWR0aD0iMTExOC4wMyIgaGVpZ2h0PSI5NjIuODQiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0ic2hhcGUiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMTAiIHJlc3VsdD0iZWZmZWN0MV9mb3JlZ3JvdW5kQmx1cl82Nl8xOTEiLz4KPC9maWx0ZXI+CjxyYWRpYWxHcmFkaWVudCBpZD0icGFpbnQwX3JhZGlhbF82Nl8xOTEiIGN4PSIwIiBjeT0iMCIgcj0iMSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIGdyYWRpZW50VHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTM1OC4wNCAxODEuMTk0KSByb3RhdGUoMTc4LjYzOSkgc2NhbGUoMTAxMi40NyAzMDUuOTE1KSI+CjxzdG9wIHN0b3AtY29sb3I9IiNGREU0RkQiLz4KPHN0b3Agb2Zmc2V0PSIwLjQ1NCIgc3RvcC1jb2xvcj0iIzY1RENGQiIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMzMDMyNjYiIHN0b3Atb3BhY2l0eT0iMCIvPgo8L3JhZGlhbEdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=");
// }

.inner {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.inner label {
    color: var(--color-secondary-variant);
}

.header-bar {
    @include screen-lerp(height, 100px, 200px, 560px, 768px);
}

.header img {
    @include responsive-field-x4(content, '@/assets/imgs/gem/gem-protocol.png');
    margin-bottom: 8px;
}

</style>