<template>
    <PageBaseLayout>
        <template #main>
            <div class="container info-layout">
                <div class="header-img-container rounded">
                    <slot name="header-img">
                        <div class="header-img"></div>
                    </slot>
                </div>
                <br>
                <div class="content-container">
                    <p class="title large"><slot name="header-super"></slot></p>
                    <h4 class="colored"><slot name="header"></slot></h4>
                    <p class="title large"><slot name="header-sub"></slot></p>
                    <br>
                    <div class="text-left">
                        <p class="large"><slot name="title"></slot></p>
                        <p class="title large"><slot name="subtitle"></slot></p>
                        <br>
                        <p><slot name="content"></slot></p>
                        <slot name="content-div"></slot>
                    </div>
                </div>
            </div>
        </template>

        <template #bottom>
            <slot name="bottom"></slot>
        </template>
    </PageBaseLayout>

</template>

<style>
.info-layout .header-img {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url('@/assets/imgs/img-icon.png');
    background-size: contain;
}
</style>

<style scoped>
.content-container {
    padding: 12px;
}

.header-img-container {
    background-color: var(--color-primary-dark);
    height: 178px;
    overflow: hidden;
}
</style>