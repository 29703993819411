<template>
    <InfoLayout1>
        <template #header-img><div class="header-img"></div></template>
        
        <template #title>A Rare Honor,</template>
        <template #subtitle>An Invitation Like No Other</template>    

        <template #content-div>
            <p>Welcome, esteemed connoisseur. <br>Your invitation code isn’t just a string of characters—it’s the key to a realm reserved for a select few. <br>With this exclusive code, you gain privileged early access to our Masterpiece and Legendary Tiers, where rarity meets innovation. <br>Here, every round is meticulously crafted for those who truly stand apart.</p>
            <br>
            
            <p>As an Honorary VIP, you’re not simply participating—you’re setting the standard. Your elite status grants you a front-row seat to experiences that few ever witness. <br>By submitting your invitation code and wallet address, you confirm your place among a distinguished circle of visionaries, ensuring that every opportunity is tailored to your exceptional taste.</p>
            <br>

            <p>Please enter your details below to secure your whitelisted status. Once verified, you’ll unlock access to early rounds and a suite of unparalleled privileges. Welcome to the inner sanctum of digital art excellence—where prestige and opportunity converge.</p>

        </template>

        <template #bottom>
            <div class="bottom">
                <BaseButton pageId="HonoraryInvitations01">Proceed &nbsp;<i class="icon middle next"></i></BaseButton>
            </div>
        </template>
    </InfoLayout1>
</template>

<style scoped>
div.header-img {
    background-image: url('@/assets/imgs/invitations/header.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
</style>