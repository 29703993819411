<template>
    <InfoLayout1>
        <template #header-img><div class="header-img"></div></template>
        <template #header-super>Pioneering the Future: The First</template>
        <template #header>10K NFT Collection</template>
        <template #header-sub>on ERC-7160</template>
        
        <template #content-div>
            <p>At the core of Alpha G.O.A.T is innovation, and we’re proud to be the first 10,000-piece NFT collection built on ERC-7160—a cutting-edge Ethereum standard designed to push the boundaries of what NFTs can do.</p>
            <br>
            <p><span class="title large">Why ERC-7160?</span> <br>
                Traditional NFTs, like those built on ERC-721 or ERC-1155, have served us well, but they come with limitations. Static metadata, rigid functionalities, and lack of modularity often restrict how NFTs evolve over time. ERC-7160 changes the game.</p>
            <br>
            <p>This new standard introduces modularity, dynamic properties, and enhanced cross-compatibility, making our NFTs smarter, more adaptable, and future-proof. With ERC-7160, Alpha G.O.A.T NFTs can:</p>

            <div class="point-list">
                <p>✅ Evolve over time with upgradable metadata and programmable traits</p>
                <p>✅ Seamlessly integrate with multiple platforms and applications</p>
                <p>✅ Enable enhanced attestations, making each NFT more than just a collectible</p>
                <p>✅ Support complex utilities, unlocking new interactions and functionalities</p>
            </div>
            <br>

            <p><span class="title large">Building for the Future </span><br> 
                We’re not just launching a collection—we’re setting a new standard for how NFTs should be built. By leveraging ERC-7160, we ensure that Alpha G.O.A.T is not just relevant today, but remains innovative for years to come.</p>
            <br>

            <p>This is more than an NFT drop—it’s an experiment in the future of digital ownership. Are you ready to be part of it? 🚀</p>

        </template>

        <template #bottom>
            <div class="bottom">
                <BaseButton pageId="AlphaGoat03">What else? <i class="icon middle next"></i></BaseButton>
            </div>
        </template>
    </InfoLayout1>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

div.header-img {
    @include responsive-bg-x4('@/assets/imgs/about/erc7160.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.point-list {
    padding-top: .5em;

    p { 
        margin-bottom: .5em;
    }
}
</style>