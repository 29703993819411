<template>
    <PageBaseLayout>
        <template #main>
            <div class="container">
                <div class="card celebrate text-left">
                    <h6>Congrats!</h6>
                    <p class="large" style="margin-top: 10px;">You’ve Mastered the Game!</p>
                </div>

                <div class="text-left" style="margin-top: 20px; padding: 20px;">
                    <p><span class="title small">Well done, legend!</span></p>
                    <p>You’ve unlocked the full <span class="title small">GEM</span> experience, from <span class="title small">Voting</span> to <span class="title small">Auctions</span>—you're ready for anything.</p>
                    <br>                    
                    <p>But don’t get too comfortable, because there’s a special surprise waiting for you back on the home screen.</p>
                </div>
            </div>
        </template>

        <template #bottom>
            <div class="bottom">            
                <BaseButton class="hollow" pageId="HomePage" @button-clicked="setCompleted">Back to Base! &nbsp;<i class="icon middle home"></i></BaseButton>
            </div>
        </template>
    </PageBaseLayout>
</template>

<script>
import storage from '@/storage.js';

export default {
    methods: {
        setCompleted() {
            storage.setItem('doneTutorial', '1');
        }
    }
};
</script>

<style lang="scss" scoped>
.container .card {
    height: 204px;
}
</style>