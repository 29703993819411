<template>
    <InfoLayout1 class="bottom-pad-override">
        <template #header-img><div class="header-img"></div></template>
        <template #subtitle>Disclaimer</template>
        <template #content-div>
            <p>Welcome to Alpha Labs bot, your gateway to the world of Alpha G.O.A.Ts!</p>
            <p>Before you dive in, let’s get one thing straight—this is NOT financial advice, investment guidance, or a promise of any kind of return. We’re here to build, create, and have fun, but what happens next is entirely up to the community.</p><br>
            <p>By engaging with this mini-app and the NFT collection we are promoting, you acknowledge that this is an experimental project. We are passionate about innovation, and we’ll do our best to develop, improve, and push boundaries—but there are no guarantees. Like any experiment, things can evolve unpredictably, and sometimes, they can fail.</p><br>
            <p>The value of these NFTs is determined solely by the community and broader market forces. The team and founders hold zero responsibility for any financial losses, fluctuations in value, or unmet expectations. If you choose to participate, do so because you believe in the vision, enjoy the experience, and are fully aware that any money you put in could be lost.</p><br>
            <p>If you're here for entertainment, exploration, and to be part of something new—welcome aboard! But if you’re looking for financial assurances, this might not be the right place for you. Engage responsibly, and let’s build something legendary together! 🚀</p>
        </template>
    </InfoLayout1>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

div.header-img {
    @include responsive-bg-x4('@/assets/imgs/about/disclaimer.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bottom-pad-override {
    --bottom-pad: 0px;
}
</style>