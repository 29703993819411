<template>
    <div>
        <div class="scroll-container" @scroll="checkScroll" ref="container">
            <slot></slot>
        </div>
        <div v-if="showArrow" class="down-arrow">
            <div class="back-blur-medium">
                <div>
                    <img src="@/assets/imgs/down.svg" alt="down">
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
  export default {
    inject: ['getSharedData'],
    emits: ['state-changed'],
    mounted() {
        this.checkScroll();
        this.emitStateChanged();
        window.addEventListener("resize", this.checkScroll);

        const instance = this;
        this.getSharedData().scrollBy = (x,y) => instance.$refs.container.scrollBy(x,y);
    },
    unmounted() {
        window.removeEventListener("resize", this.checkScroll);

        const sharedData = this.getSharedData();
        delete sharedData.scrollBy;
    },
    methods: {
        checkScroll() {
            const container = this.$refs.container;
            if (!container) {
                return;
            }
            const scrollHeight = container.scrollHeight - 1; // tolerance offset: 1
            const canScroll = scrollHeight > container.clientHeight;
            const reachedBottom = container.scrollTop + container.clientHeight >= scrollHeight;
            const wasShowingArrow = this.showArrow;
            
            this.showArrow = canScroll && !reachedBottom;
            
            if (this.showArrow != wasShowingArrow) {
                this.emitStateChanged();
            }
        },
        
        emitStateChanged() {
            this.$emit('state-changed', this.showArrow);
        }
    },
    data() {
      return {
        showArrow: true,
      };
    },
  };
</script>
  
<style lang="scss">
.scroll-container {
    padding-top: 60px;
    height: var(--vh, 100vh);
    overflow-y: auto;
    overflow-x: hidden;
}
  
.down-arrow {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: var(--bottom-pad, 100px);
    width: 100%;
    pointer-events: none;

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;

        border-radius: 50%;
    }
}
</style>
