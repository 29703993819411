<template>
    <SurveyLayout class="override" :progressStep="11" :questionId="11" :isWalletInput="true" nextPageId="SurveyPageEnd" nextButtonTitle="Submit and become a Contributor">
        <template #card-title>Become a Contributor</template>
        <template #question>Submit your Wallet Address to be Whitelisted</template>
        
        <template #help-title>No Ads, Ever!</template>
        <template #help-content>
            <p>The email address is optional and will be used to notify you of the collection launch date if entered.</p>
        </template>
    </SurveyLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/survey/wallet.png');
}

</style>