const map = {};

export function cacheVideo(url) {
    map[url] = new VideoPreloader(url);
}

export async function loadCachedUrl(url) {
    if (url in map) {
        const preloader = map[url];
        return await preloader.resolveUrl();
    }

    return url;
}

class VideoPreloader {
    constructor(videoUrl) {
        this.blob = null;

        this.initPromise = this.init(videoUrl);
    }

    async init(videoUrl) {
        const response = await fetch(videoUrl);
        this.blob = await response.blob();
    }

    async resolveUrl() {
        await this.initPromise;
        return URL.createObjectURL(this.blob);
    }
}
