<template>
    <InfoLayout1>
        <template #header-img><div class="header-img"></div></template>
        <template #header>Gamification</template>
        <template #title>Gamification:</template>
        <template #subtitle>Welcome to the Game of Innovation</template>

        <template #content-div>
            <p>At Alpha Labs, we’re firm believers that gamification is the missing piece in the current NFT space. It's not just about minting, buying, and selling—it's about creating a full-blown experience, a game that unfolds in real-time. And we’re not talking about random drops or one-off rewards. We’ve designed every aspect of this collection—from the launch protocol to the features and beyond—as part of a living, breathing game.</p>
            <br>
            <p>Think of it like this: You’re not just buying into an NFT collection. You’re entering a world with rules, challenges, and rewards that evolve as you interact with it. Every drop, every update, every perk, every tier—they’re all pieces of a bigger puzzle, and only by staying engaged can you unlock the true depth of the experience. It’s about the journey, not the destination.</p>
            <br>
            <p>We believe that this is what the NFT space needs—a shift from passive ownership to active participation. The power of gamification lies in its ability to make you part of something bigger, something immersive, and something that rewards your engagement over time. So, as you go along, you’ll discover that the more you interact, the more you unlock, and the more your role in this ecosystem becomes meaningful. This isn’t just about collecting digital art—it’s about playing a game that could redefine what NFTs can be.</p>
            <br>
            <p>The fun? That’s just the beginning. The real adventure? Well, that’s unfolding right in front of you, and you’re part of it from day one. This is just the start of a long game, and every step you take will bring new surprises and rewards. Ready to play?</p>
            <br>
        </template>
        
        <template #bottom>
            <div class="bottom">
                <BaseButton pageId="HomePage" class="hollow">Home Sweet Home <i class="icon middle home"></i></BaseButton>
            </div>
        </template>
    </InfoLayout1>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

div.header-img {
    @include responsive-bg-x4('@/assets/imgs/about/gamification.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bottom button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

</style>