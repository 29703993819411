<template>
    <InfoLayout2 :nextPage="{buttonTitle: 'Noted!', id: 'GemMechanism07'}">
        <template #page-title>Regular Round: <br>The Rules of the Game</template>

        <template #header-title>&nbsp;</template>

        <template #main>
            <div class="list">
                <div class="item rounded">
                    <div>
                        <p>Time is ticking – each voting round runs for a <span class="title small">limited duration</span>.</p>
                    </div>
                    <div class="bg bg1"></div>
                </div>

                <div class="item rounded">
                    <div class="bg bg2"></div>
                    <div>
                        <p>Majority wins – the Alpha G.O.A.T with <span class="title small">the most votes</span> joins the <span class="title small">Final Collection</span>.</p>
                    </div>
                </div>

                <div class="item rounded">
                    <div>
                        <p>One wallet, one vote – fairness is key, <span class="title small">no extra votes</span>, no second chances.</p>
                    </div>
                    <div class="bg bg3"></div>
                </div>
            </div>
        </template>
    </InfoLayout2>
</template>


<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 10px;
    margin-top: -32px;

    .item {                
        display: flex;
        align-items: center;
        height: 180px;
        padding: 20px;        
        background-color: var(--color-secondary-dark);
        border: solid 1px var(--color-secondary);

        & > div {
            width: 50%;
        }

        .bg {
            padding: 10px;            
            height: 100%;
            background-origin: content-box;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
}

.bg1 { @include responsive-bg-x4('@/assets/imgs/rounds/time-limit.png'); }
.bg2 { @include responsive-bg-x4('@/assets/imgs/rounds/goat-badge.png'); }
.bg3 { @include responsive-bg-x4('@/assets/imgs/rounds/ticket.png'); }

</style>