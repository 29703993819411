<template>
    <h3 class="light">{{counterText}}</h3>
    <template v-if="showCaption && counterText">
        <p v-if="plural" class="small light">Remaining Contributor Whitelist Spots</p>
        <p v-else class="small light">Remaining Contributor Whitelist Spot</p>
    </template>
</template>

<script>
import * as backend from '@/backend.js';

export default {
    mounted() {
        this.updateCounter();
    },
    methods: {
        async updateCounter() {
            const data = await backend.fetchSpotsStatus();
            const remaining = Math.max(0, data.total - data.filled);
            if(remaining > 0) {
                this.counterText = `${remaining} of ${data.total ?? 0}`;
                this.showCaption = true;
            } else {
                this.counterText = "Ready for Launch!";
                this.showCaption = false;
            }
            this.plural = remaining > 1;
        }
    },
    data() {
        return {
            counterText: '',
            plural: true,
            showCaption: true,
        };
    }
}
</script>