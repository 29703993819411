<template>
    <link v-for="item in preloadImages" :key="item" rel="preload" as="image" :href="item">
</template>

<script>
import { cacheVideo } from '@/videoManager';

export default {
    mounted() {
        cacheVideo(require('@/assets/vids/gem-header.mp4'));
        cacheVideo(require('@/assets/vids/intro-1.mp4'));
        cacheVideo(require('@/assets/vids/intro-2.mp4'));
        cacheVideo(require('@/assets/vids/intro-3.mp4'));
        cacheVideo(require('@/assets/vids/intro-4.mp4'));
    },
    data() {
        const resolve = require.context('@');

        function requireResponsive(assetPath) {
            let modifier = '';
            const idx = assetPath.lastIndexOf('.');
            if(window.devicePixelRatio > 1) {
                if (window.devicePixelRatio < 2.5) {
                    modifier = '@2x';
                } else if (window.devicePixelRatio < 3.5) {
                    modifier = '@3x';
                } else if (window.devicePixelRatio >= 3.5) {
                    modifier = '@4x';
                }
            }
            
            const path = assetPath.substring(0,idx) + modifier + assetPath.substring(idx);
            return resolve(path);
        }

        const preloadImages = [
            require('@/assets/imgs/back.svg'),
            require('@/assets/imgs/down-icon.svg'),
            require('@/assets/imgs/down.svg'),
            require('@/assets/imgs/forward.svg'),
            require('@/assets/imgs/goat-nft-1.jpg'),
            require('@/assets/imgs/goat-nft-2.jpg'),
            require('@/assets/imgs/home-buttons-bg.svg'),
            require('@/assets/imgs/img-icon.png'),
            require('@/assets/imgs/info-icon.svg'),
            require('@/assets/imgs/logo-notext.svg'),
            require('@/assets/imgs/logo.svg'),
            require('@/assets/imgs/logo-light.svg'),
            require('@/assets/imgs/nft-grid-0.png'),
            require('@/assets/imgs/site-discord.svg'),
            require('@/assets/imgs/site-warpcast.svg'),
            require('@/assets/imgs/site-web.svg'),
            require('@/assets/imgs/site-x.svg'),
            require('@/assets/imgs/icon-manifesto.svg'),
            require('@/assets/imgs/icon-disclaimer.svg'),
            require('@/assets/imgs/tiers-sheet.svg'),
            require('@/assets/imgs/x.svg'),
            require('@/assets/imgs/survey/help.svg'),            

            require('@/assets/imgs/invitations/header.svg'),

            require('@/assets/imgs/gem/evo-mint.svg'),

            requireResponsive('./assets/imgs/home/home-bg.png'),

            requireResponsive('./assets/imgs/rounds/chest.png'),
            requireResponsive('./assets/imgs/rounds/double-reward.png'),
            requireResponsive('./assets/imgs/rounds/fence.png'),
            requireResponsive('./assets/imgs/rounds/gifts.png'),
            requireResponsive('./assets/imgs/rounds/goat-badge.png'),
            requireResponsive('./assets/imgs/rounds/goat.png'),
            requireResponsive('./assets/imgs/rounds/shares.png'),
            requireResponsive('./assets/imgs/rounds/target.png'),
            requireResponsive('./assets/imgs/rounds/ticket.png'),
            requireResponsive('./assets/imgs/rounds/time-limit.png'),

            requireResponsive('./assets/imgs/auction/auction-time.png'),
            requireResponsive('./assets/imgs/auction/bid-coin.png'),
            requireResponsive('./assets/imgs/auction/coins.png'),
            requireResponsive('./assets/imgs/auction/tiers.png'),

            requireResponsive('./assets/imgs/contrib/contrib.png'),
            requireResponsive('./assets/imgs/survey/wallet.png'),

            requireResponsive('./assets/imgs/about/disclaimer.png'),
            requireResponsive('./assets/imgs/about/manifesto.png'),
            requireResponsive('./assets/imgs/about/erc7160.png'),
            requireResponsive('./assets/imgs/about/gamification.png'),
            requireResponsive('./assets/imgs/about/perks.png'),

            requireResponsive('./assets/imgs/gem/gem-protocol.png'),
        ];
    
        return {
            preloadImages
        };
    }
};
</script>