import * as survey from "@/survey.js";
import * as errors from "@/errors.js";

function validateTelegramSupport() {
    if(!(window?.Telegram?.WebApp?.initData)) {
        alert('Unsupported platform!');
        console.error('unsupported platform!');
        return false;
    }

    return true;
}

function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

async function sendRequest(endpoint, data = null) {
    if (!validateTelegramSupport()) {
        return errors.create(errors.ERROR_IDS.INVALID_PLATFORM);
    }

    const telegramAuth = window.Telegram.WebApp.initData;
    const requestOptions = {
        method: data ? 'POST' : 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'X-Telegram-Auth': telegramAuth
        },    
    };

    if (data) {
        requestOptions.body = JSON.stringify(data)
    }

    try {
        const response = await fetch(endpoint, requestOptions);
        if (!response.ok) {
            console.error({code: response.status, status: response.statusText});
            return errors.create(errors.ERROR_IDS.UNKNOWN_ERROR);
        }
        let data = await response.json();
        return data;
    } catch(e) {
        return errors.create(errors.ERROR_IDS.UNKNOWN_ERROR);
    }
}

export async function submitSurvey() {
    return await sendRequest('/api/survey', survey.serialize(false));
}

export async function submitInvitation(code, wallet, email) {
    const INVITATION_REGEX = /VIP-[0-9]+-[A-Z0-9]+/;
    if (!INVITATION_REGEX.test(code)) {
        await delay(500);
        return errors.create(errors.ERROR_IDS.INVALID_INVITATION_CODE);
    }

    return await sendRequest('/api/invitation', {code, wallet, email});
}

export async function fetchSpotsStatus() {
    return await sendRequest('/api/spots');
}